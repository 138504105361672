<template>
  <Header />
  <main>
    <div class="cont">
      <template v-if="facility">
        <h1>{{ facility.name }}</h1>
        <div class="info-notes">
          <p>
            <span v-if="facility.address">{{ facility.address }}</span>
            <span v-if="facility.tel">（{{ facility.tel }}）</span>
            <span v-if="facility.bed_count">（ベッド数：{{ facility.bed_count }}台）</span>
            <span v-if="facility.manager">施設長：{{ facility.manager }}</span>
            <span v-if="facility.person_in_charge">担当：{{ facility.person_in_charge }}</span>
          </p>
        </div>
      </template>

      <section>
        <h2>{{ targetUser.room_name }} / {{ targetUser.name }}さんの詳細</h2>

        <div class="btns w100">
          <button v-on:click="showFacilityManager">ダッシュボード</button>
          <button v-on:click="showRealTime">リアルタイムデータ</button>
          <button v-on:click="showFacilityConfigMenu">設定</button>
          <p>
            <span>S/N番号</span>
            <span v-for="deviceId in targetUser.device_id_list" :key="deviceId">{{ deviceId }}</span>
          </p>
        </div>

        <div>
          <template v-if="!showingOneWeek">
          <div class="chart-area now">
            <div class="head-tit">
              <h3>活動状況</h3>
              <button v-on:click="showOneWeek" class="linktxt">1週間データへ</button>
            </div>
            <div class="tl-nav">
              <p>{{ today.toLocaleDateString() }}</p>

              <div class="btns linktxt">
                <label><input type="radio" id="searchCompanyStatusOption1" :value="24" v-model="timelineLength" />24時間</label>
                <label><input type="radio" id="searchCompanyStatusOption2" :value="12" v-model="timelineLength" />12時間</label>
                <label><input type="radio" id="searchCompanyStatusOption3" :value="6" v-model="timelineLength" />6時間</label>
              </div>
            </div>

            <div class="chart-container">
              <TimelineChart :data="timelineChartData" :begin="timelineBegin" :end="timelineEnd"/>
              <div class="legend">
                <div class="legend-item">
                  <div class="active-color-sample"></div>
                  <span>有活動</span>
                </div>
                <div class="legend-item">
                  <div class="non-active-color-sample"></div>
                  <span>微活動</span>
                </div>
                <div class="legend-item">
                  <div class="sleep-color-sample"></div>
                  <span>就寝中</span>
                </div>
              </div>
            </div>
            <p class="note-r">※無人の場合でも有活動と表示されることがあります。</p>
          </div>

          <div class="chart-area">
            <div class="chart-container">
              <h3>睡眠データ</h3>
              <SleepChart :data="sleepChartData" :begin="timelineBegin" :end="timelineEnd"/>
              <div class="legend">
                <div class="legend-item">
                  <div class="non-rem-color-sample"></div>
                  <span>深い眠り</span>
                </div>
                <div class="legend-item">
                  <div class="rem-color-sample"></div>
                  <span>浅い眠り</span>
                </div>
                <div class="legend-item">
                  <div class="awake-color-sample"></div>
                  <span>有活動</span>
                </div>
              </div>
            </div>
            <div class="score-area">
              <div>
                <dl v-if="bestSleepScore !== null">
                  <dt><span>睡眠スコア</span></dt>
                  <dd>{{ bestSleepScore }}</dd>
                </dl>
                <dl v-if="totalNremLength !== null">
                  <dt><span>深い眠り合計時間</span></dt>
                  <dd>{{ renderTimeLength(totalNremLength) }}</dd>
                </dl>
                <dl v-if="totalRemLength !== null">
                  <dt><span>浅い眠り合計時間</span></dt>
                  <dd>{{ renderTimeLength(totalRemLength) }}</dd>
                </dl>
                <dl v-if="totalWakeUpCount !== null">
                  <dt><span>目覚め</span></dt>
                  <dd>{{ totalWakeUpCount }}回</dd>
                </dl>
                <dl v-if="totalActiveLength !== null">
                  <dt><span>目覚め合計時間</span></dt>
                  <dd>{{ renderTimeLength(totalActiveLength) }}</dd>
                </dl>
              </div>
            </div>
          </div>

          </template>
          <template v-else>
          <div class="chart-area">
            <div class="head-tit">
              <h3>一週間の活動状況<span>グラフ部分をクリックで睡眠スコアを表示</span></h3>
              <button v-on:click="showOneDay" class="linktxt">1日のデータへ</button>
            </div>
            <div class="chart-container">
              <OneWeekTimelineChart :data="oneWeekChartData" :begin="oneweekTimelineBegin" :end="oneweekTimelineEnd" @handleChartClick="handleOneWeekChartClick"/>
              <div class="legend">
                <div class="legend-item">
                  <div class="active-color-sample"></div>
                  <span>有活動</span>
                </div>
                <div class="legend-item">
                  <div class="sleep-color-sample"></div>
                  <span>就寝中</span>
                </div>
                <div class="legend-item">
                  <div class="non-active-color-sample"></div>
                  <span>微活動</span>
                </div>
              </div>
            </div>
            <p class="note-r">※無人の場合でも有活動と表示されることがあります。</p>
            <div class="daybtn">
              <h3>各曜日の詳細を表示する</h3>
              <button v-on:click="handleOneWeekChartClick(7)">{{ getDayStringNDaysAgo(7) }}</button>
              <button v-on:click="handleOneWeekChartClick(6)">{{ getDayStringNDaysAgo(6) }}</button>
              <button v-on:click="handleOneWeekChartClick(5)">{{ getDayStringNDaysAgo(5) }}</button>
              <button v-on:click="handleOneWeekChartClick(4)">{{ getDayStringNDaysAgo(4) }}</button>
              <button v-on:click="handleOneWeekChartClick(3)">{{ getDayStringNDaysAgo(3) }}</button>
              <button v-on:click="handleOneWeekChartClick(2)">{{ getDayStringNDaysAgo(2) }}</button>
              <button v-on:click="handleOneWeekChartClick(1)">{{ getDayStringNDaysAgo(1) }}</button>
            </div>
            <div class="score-area">
              <p class="score-date" v-if="currentScoreDetailDate !== null">{{ currentScoreDetailDate }}の詳細</p>
              <div>
                <dl v-if="bestSleepScoreForOneWeek !== null">
                  <dt><span>睡眠スコア</span></dt>
                  <dd>{{ bestSleepScoreForOneWeek }}</dd>
                </dl>
                <dl v-if="totalNremLengthForOneWeek !== null">
                  <dt><span>深い眠り合計時間</span></dt>
                  <dd>{{ renderTimeLength(totalNremLengthForOneWeek) }}</dd>
                </dl>
                <dl v-if="totalRemLengthForOneWeek !== null">
                  <dt><span>浅い眠り合計時間</span></dt>
                  <dd>{{ renderTimeLength(totalRemLengthForOneWeek) }}</dd>
                </dl>
                <dl v-if="totalWakeUpCountForOneWeek !== null">
                  <dt><span>目覚め</span></dt>
                  <dd>{{ totalWakeUpCountForOneWeek }}回</dd>
                </dl>
                <dl v-if="totalActiveLengthForOneWeek !== null">
                  <dt><span>目覚め合計時間</span></dt>
                  <dd>{{ renderTimeLength(totalActiveLengthForOneWeek) }}</dd>
                </dl>
              </div>
            </div>
          </div>

          </template>
        </div>

        <div class="note">
          <h3>メモ</h3>
          <ul ref="note">
            <li v-for="memo in memos" :key="memo.id">
              <p><span class="date">{{ formatTime(memo.created_at) }}</span><em class="describer">{{ memo.writer_name }}</em></p>
              <p class="memo">{{ memo.body }}</p>
            </li>
          </ul>
          <div class="memo-ipt">
            <p>{{ today.toLocaleDateString('ja-JP') }}</p>
            <p><input type="text" v-model="newMemoBody" placeholder="本文（必須）"></p>
            <p>
              <select v-model="newMemoWriter">
                <option value="">担当者を選択</option>
                <option v-for="facilityAdminUser in facilityAdminUsers" :key="facilityAdminUser.id" :value="facilityAdminUser.name">{{ facilityAdminUser.name }}</option>
              </select>
              <button v-on:click="sendMemo">メモを保存</button>
            </p>
          </div>
        </div>
      </section>
    </div>
  </main>
  <Footer />
</template>

<script>
import Header from '../../Header'
import Footer from '../../Footer'
import { apiGet, apiPost } from '../../../api'
import TimelineChart from './TimelineChart'
import SleepChart from './SleepChart'
import OneWeekTimelineChart from './OneWeekTimelineChart'

const timelineColorMap = {
  '有活動': 'rgba(255, 205, 85, 1.0)',
  '微活動': 'rgba(190, 190, 190, 1.0)',
  '就寝中': 'rgba(100, 105, 205, 1.0)',
}

const sleepColorMap = [
  'rgba(255, 215, 0, 1.0)', // awake
  'rgba(55, 90, 180, 1.0)', // non rem
  'rgba(150, 190, 250, 1.0)', // rem
]

const sleepLabels = [
  '有活動',
  '深い眠り',
  '浅い眠り'
]

const oneDayUnixTime = 24 * 60 * 60 * 1000

export default {
  name: 'TargetUserDetailTimeline',
  components: {
    Header,
    Footer,
    TimelineChart,
    SleepChart,
    OneWeekTimelineChart,
  },
  data () {
    const beginDate = new Date()
    beginDate.setDate(beginDate.getDate() - 1)
    beginDate.setHours(6, 0, 0, 0)
    const endDate = new Date()
    endDate.setHours(6, 0, 0, 0)

    return {
      facility: null,
      targetUser: {
        name: "",
      },
      facilityId: this.$route.query.facility_id,
      targetUserId: this.$route.query.target_user_id,
      memos: [],
      facilityAdminUsers: [],
      newMemoBody: '',
      newMemoWriter: '',
      today: new Date(),
      isLoading: false,
      timelineLength: 24,
      showingOneWeek: false,
      timelineChartData: {
        datasets: []
      },
      sleepChartData: {
        datasets: []
      },
      oneWeekChartData: {
        datasets: []
      },
      timelineBegin: null,
      timelineEnd: null,
      oneweekTimelineBegin: Math.floor(beginDate.getTime() / 1000),
      oneweekTimelineEnd: Math.floor(endDate.getTime() / 1000),
      sleepScoreTextData: [],
      bestSleepScore: null,
      totalNremLength: null,
      totalRemLength: null,
      totalWakeUpCount: null,
      totalActiveLength: null,
      currentScoreDetailDate: null,
      bestSleepScoreForOneWeek: null,
      totalNremLengthForOneWeek: null,
      totalRemLengthForOneWeek: null,
      totalWakeUpCountForOneWeek: null,
      totalActiveLengthForOneWeek: null,
    }
  },
  watch: {
    timelineLength: async function() {
      this.loadTimelineData()
    }
  },
  mounted() {
    this.loadFacility()
    this.loadTargetUser()
    this.loadMemo()
    this.loadFacilityAdminUsers()
    this.loadTimelineData()
  },
  methods: {
    loadFacility: async function() {
      try {
        const response = await apiGet(`/api/facility?id=${this.facilityId}`, this.$router.app.$cookies)
        this.facility = response.data
      } catch (error) {
        console.error(error)
      }
    },
    loadTargetUser: async function() {
      const response = await apiGet(`/api/target_user?target_user_id=${this.targetUserId}`, this.$router.app.$cookies)
      this.targetUser = response.data
    },
    loadFacilityAdminUsers: async function() {
      const response = await apiGet(`/api/user_facility_relation?facility_id=${this.facilityId}`, this.$router.app.$cookies)
      this.facilityAdminUsers = response.data
    },
    formatTime: function(datestr) {
      const unixtime = Date.parse(datestr)
      const date = new Date(unixtime)
      return date.toLocaleString('ja-JP')
    },
    showOneDay() {
      this.showingOneWeek = false
    },
    showOneWeek() {
      this.showingOneWeek = true
    },
    handleOneWeekChartClick: async function(nDaysAgo) {
      const dateString = this.getDayStringNDaysAgo(nDaysAgo)
      this.currentScoreDetailDate = dateString
      this.totalActiveLengthForOneWeek = null
      this.bestSleepScoreForOneWeek = null
      this.totalNremLengthForOneWeek = null
      this.totalRemLengthForOneWeek = null
      this.totalWakeUpCountForOneWeek = null

      const now = new Date()

      const beginDate = new Date(now)
      beginDate.setDate(now.getDate() - nDaysAgo)
      beginDate.setHours(6, 0, 0, 0)
      const begin = Math.floor(beginDate.getTime() / 1000)

      const endDate = new Date(now)
      endDate.setDate(now.getDate() - nDaysAgo + 1)
      endDate.setHours(6, 0, 0, 0)
      const end = Math.floor(endDate.getTime() / 1000)

      const response = await apiGet(
        `/api/target_user/timeline?target_user_id=${this.targetUserId}&begin=${begin}&end=${end}`,
        this.$router.app.$cookies
      )

      const lifeLogDataResponse = response.data.life_log
      this.updatelifeLogDetailForOneWeek(lifeLogDataResponse)
      const sleepDataResponse = response.data.sleep
      this.updateSleepDetailForOneWeek(sleepDataResponse)
    },
    updatelifeLogDetailForOneWeek(lifeLogDataResponse) {
      this.totalActiveLengthForOneWeek = 0
      let beforeData = null
      lifeLogDataResponse.forEach(lifeLogData => {
        if (beforeData !== null) {
          if (beforeData.detection === 1 || beforeData.sleepStage === 0) {
            this.totalActiveLengthForOneWeek += lifeLogData.stateTime - beforeData.stateTime
          }
        }
        beforeData = lifeLogData
      })
    },
    updateSleepDetailForOneWeek(sleepDataResponse) {
      this.bestSleepScoreForOneWeek = 0
      this.totalNremLengthForOneWeek = 0
      this.totalRemLengthForOneWeek = 0
      this.totalWakeUpCountForOneWeek = 0
      sleepDataResponse.forEach(sleepData => {
        if (sleepData.sleepScore > this.bestSleepScoreForOneWeek) {
          this.bestSleepScoreForOneWeek = sleepData.sleepScore
        }
        this.totalNremLengthForOneWeek += sleepData.timeInNrem
        this.totalRemLengthForOneWeek += sleepData.timeInRem
        this.totalWakeUpCountForOneWeek += sleepData.wakeUpCount
      })
    },
    loadTimelineData: async function() {
      try {
        this.timelineEnd = Math.floor(Date.now() / 1000)
        this.timelineBegin = this.timelineEnd - this.timelineLength * 60 * 60

        const response = await apiGet(
          `/api/target_user/timeline?target_user_id=${this.targetUserId}&begin=${this.timelineBegin}&end=${this.timelineEnd}`,
          this.$router.app.$cookies
        )
        this.renderTimelineData(response.data.life_log)
        this.renderSleepData(response.data.sleep, response.data.sleep_score_texts)

        const oneWeekResponse = await apiGet(
          `/api/target_user/one_week_life_log?target_user_id=${this.targetUserId}`,
          this.$router.app.$cookies
        )

        const oneWeekTimeline = oneWeekResponse.data
        if (oneWeekTimeline !== null && oneWeekTimeline.length > 0) {
          const lastDayData = oneWeekTimeline[oneWeekTimeline.length - 1]
          const now = new Date()
          const sixAM = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 6, 0, 0, 0)
          const endTimestamp = Math.floor(sixAM.getTime() / 1000)
          lastDayData.push({stateTime: endTimestamp, detection: 0})
        }
        this.renderOneWeekTimelineData(oneWeekResponse.data)
      } catch (error) {
        console.log(error)
      }
      this.isLoading = false
    },
    renderTimelineData: function(lifeLogDataResponse) {
      if (typeof lifeLogDataResponse === "undefined") {
        this.timelineChartData = {
          datasets: []
        }
        return
      }

      const timelineDataList = []
      const timelineColorList = []

      this.totalActiveLength = 0
      let beforeData = null
      const lastDataAdded = lifeLogDataResponse
      lastDataAdded.push({stateTime: this.timelineEnd, detection: 0})
      lastDataAdded.forEach(lifeLogData => {
        if (beforeData !== null) {
          if (beforeData.detection === 1 || beforeData.sleepStage === 0) {
            timelineDataList.push({
              x: [new Date(beforeData.stateTime * 1000), new Date(lifeLogData.stateTime * 1000)],
              y: '有活動'
            })
            timelineColorList.push(timelineColorMap['有活動'])
            this.totalActiveLength += lifeLogData.stateTime - beforeData.stateTime
          } else if (beforeData.detection === 0) {
            timelineDataList.push({
              x: [new Date(beforeData.stateTime * 1000), new Date(lifeLogData.stateTime * 1000)],
              y: '微活動'
            })
            timelineColorList.push(timelineColorMap['微活動'])
          } else if (beforeData.sleepStage === 1 || beforeData.sleepStage === 2) {
            timelineDataList.push({
              x: [new Date(beforeData.stateTime * 1000), new Date(lifeLogData.stateTime * 1000)],
              y: '就寝中'
            })
            timelineColorList.push(timelineColorMap['就寝中'])
          }
        }
        beforeData = lifeLogData
      })
      this.timelineChartData = {
        labels: ['有活動','微活動','就寝中'],
        datasets: [
          {
            data: timelineDataList,
            backgroundColor: timelineColorList,
            borderWidth: 0,
            barPercentage: 0.6
          },
        ]
      }
    },
    renderSleepData: function(sleepDataResponse, sleepScoreTextData) {
      if (typeof sleepDataResponse === "undefined") {
        this.sleepChartData = {
          datasets: []
        }
        return
      }

      const sleepDataList = []
      const sleepColorList = []
      this.bestSleepScore = 0
      this.totalNremLength = 0
      this.totalRemLength = 0
      this.totalWakeUpCount = 0
      sleepDataResponse.forEach(sleepData => {
        let scoreText = ''
        sleepScoreTextData.forEach((sleepScoreText) => {
          if (sleepData.sleepScore >= sleepScoreText.score_min) {
            scoreText = sleepScoreText.text
          }
        })
        scoreText = '睡眠スコア:' + String(sleepData.sleepScore)

        if (sleepData.sleepScore > this.bestSleepScore) {
          this.bestSleepScore = sleepData.sleepScore
        }
        this.totalNremLength += sleepData.timeInNrem
        this.totalRemLength += sleepData.timeInRem
        this.totalWakeUpCount += sleepData.wakeUpCount

        let beforeTime = 0
        let beforeStage = 0
        sleepData.stages.forEach((stage, index) => {
          if (beforeTime !== 0){
            sleepDataList.push({
              x: [new Date(beforeTime * 1000), new Date(stage.time * 1000)],
              y: 1,
              label: sleepLabels[beforeStage],
              scoreText: scoreText
            })
            sleepColorList.push(sleepColorMap[beforeStage])
          }
          beforeTime = stage.time
          beforeStage = stage.stage

          if (index === sleepData.stages.length - 1) {
            sleepDataList.push({
              x: [new Date(beforeTime * 1000), new Date(sleepData.wakeup * 1000)],
              y: 1,
              label: sleepLabels[beforeStage],
              scoreText: scoreText
            })
            sleepColorList.push(sleepColorMap[beforeStage])
          }
        })
      })
      this.sleepChartData = {
        datasets: [
          {
            data: sleepDataList,
            backgroundColor: sleepColorList,
            barPercentage: 0.6
          },
        ]
      }
    },
    renderOneWeekTimelineData: function(oneWeekData) {
      if (oneWeekData === null) {
        this.oneWeekChartData = {
          datasets: []
        }
        return
      }

      const timelineDataList = []
      const timelineColorList = []
      for (let currentIndex = 0; currentIndex < oneWeekData.length; currentIndex++) {
        const dateString = this.getDayStringNDaysAgo(oneWeekData.length - currentIndex)
        let beforeData = null
        oneWeekData[currentIndex].forEach(lifeLogData => {
          const fakeDiff = oneDayUnixTime * (oneWeekData.length - currentIndex - 1)
          if (beforeData !== null) {
            const fakeStartTime = beforeData.stateTime * 1000 + fakeDiff
            const fakeEndTime = lifeLogData.stateTime * 1000 + fakeDiff

            if (beforeData.detection === 1 || beforeData.sleepStage === 0) {
              timelineDataList.push({
                x: [new Date(fakeStartTime), new Date(fakeEndTime)],
                y: dateString,
                nDaysAgo: oneWeekData.length - currentIndex,
                label: '有活動'
              })
              timelineColorList.push(timelineColorMap['有活動'])
            } else if (beforeData.detection === 0) {
              timelineDataList.push({
                x: [new Date(fakeStartTime), new Date(fakeEndTime)],
                y: dateString,
                nDaysAgo: oneWeekData.length - currentIndex,
                label: '微活動',
                barPercentage: 1.0,
                options: {
                  barPercentage: 1.0,
                }
              })
              timelineColorList.push(timelineColorMap['微活動'])
            } else if (beforeData.sleepStage === 1 || beforeData.sleepStage === 2) {
              timelineDataList.push({
                x: [new Date(fakeStartTime), new Date(fakeEndTime)],
                y: dateString,
                nDaysAgo: oneWeekData.length - currentIndex,
                label: '就寝中'
              })
              timelineColorList.push(timelineColorMap['就寝中'])
            }
          }
          beforeData = lifeLogData
        })
      }
      this.oneWeekChartData = {
        datasets: [
          {
            data: timelineDataList,
            backgroundColor: timelineColorList,
            barPercentage: 0.6,
          },
        ]
      }
    },
    getDayStringNDaysAgo: function(n) {
      const daysOfWeek = ["日", "月", "火", "水", "木", "金", "土"]
      const today = new Date()
      const nDaysAgo = new Date(today.getTime() - (n * 24 * 60 * 60 * 1000))
      const month = String(nDaysAgo.getMonth() + 1)
      const day = String(nDaysAgo.getDate())
      const dayOfWeek = daysOfWeek[nDaysAgo.getDay()]
      return `${month}月${day}日(${dayOfWeek})`
    },
    renderTimeLength: function(seconds) {
      const hours = Math.floor(seconds / 3600)
      const minutes = Math.floor((seconds % 3600) / 60)
      const formattedHours = hours.toString()
      const formattedMinutes = minutes.toString().padStart(2, '0')
      return `${formattedHours}:${formattedMinutes}`
    },
    sendMemo: async function() {
      try {
        if (this.newMemoBody === '' || this.newMemoWriter === '') {
          alert('本文と記入者の両方を記入してください')
          return
        }
        const response = await apiPost('/api/target_user/memo', {
          target_user_id: this.targetUserId,
          body: this.newMemoBody,
          writer_name: this.newMemoWriter
        }, this.$router.app.$cookies)

        if (response.status === 200) {
          this.newMemoBody = ''
          this.newMemoWriter = ''
          this.loadMemo()
        }
      } catch (error) {
        console.error(error.value)
      }
    },
    loadMemo: async function() {
      try {
        const response = await apiGet(`/api/target_user/memo?target_user_id=${this.targetUserId}`, this.$router.app.$cookies)
        this.memos = response.data
        const memoElement = this.$refs.note
        memoElement.scrollTop = 0
      } catch (error) {
        console.error(error.value)
      }
    },
    showFacilityManager: function() {
      this.$router.push(`/facility_manager?id=${this.facilityId}`)
    },
    showRealTime: function() {
      this.$router.push(`/target_user_detail/real_time?facility_id=${this.facilityId}&target_user_id=${this.targetUserId}`)
    },
    showFacilityConfigMenu: function() {
      this.$router.push(`/facility_config_menu?id=${this.facilityId}`)
    },
  }
}
</script>

<style scoped>
  .active-color-sample {
    background-color: rgba(255, 205, 85, 1);
  }
  .sleep-color-sample {
    background-color: rgba(100, 105, 205, 1.0);
  }
  .non-active-color-sample {
    background-color: rgba(190, 190, 190, 1);
  }
  .non-rem-color-sample {
    background-color: rgba(55, 90, 180, 1);
  }
  .rem-color-sample {
    background-color: rgba(150, 190, 250, 1);
  }
  .awake-color-sample {
    background-color: rgba(255, 215, 0, 1.0);
  }
@media all and (max-width: 480px) {
  .active-color-sample {
    width: 3vw;
    height: 3vw;
  }
  .sleep-color-sample {
    width: 3vw;
    height: 3vw;
  }
  .non-active-color-sample {
    width: 3vw;
    height: 3vw;
  }
  .non-rem-color-sample {
    width: 3vw;
    height: 3vw;
  }
  .rem-color-sample {
    width: 3vw;
    height: 3vw;
  }
  .awake-color-sample {
    width: 3vw;
    height: 3vw;
  }
  .info-notes {
    padding-bottom: 4vw;
  }
  .info-notes p {
    font-size: 3.4vw;
  }
  .cont h2 {
    font-size: 4vw;
    padding: 1.5em 0;
  }
  section h3 {
    font-size: 3.6vw;
    padding-bottom: .2em;
  }
  .btns {
    display: flex;
    flex-flow: row wrap;
  }
  .btns.w100 {
    padding: 0 0 6vw;
  }
  .btns.w100 button {
    margin-right: 4vw;
    padding: .5em 1em;
  }
  .btns.w100 p {
    font-size: 3.6vw;
    padding: .5em 0 0;
  }
  .btns.w100 p span {
    padding-right: 1em;
  }
  .btns button:last-child {
    margin-right: 0px;
  }
  .tl-nav {
    display: flex;
    flex-flow: row wrap;
    font-size: 3.6vw;
    padding-bottom: 1em;
  }
  .tl-nav p {
    padding-right: 2em;
  }
  .tl-nav .btns label {
    display: inline-block;
    padding-right: 1em;
    cursor: pointer;
  }
  .head-tit {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    border-bottom: rgb(200,200,200) dashed 1px;
    margin-bottom: .5em;
  }
  .head-tit span {
    display: block;
    font-size: 3vw;
    font-weight: normal;
  }
  .legend {
    margin-top: 1em;
    display: flex;
  }
  .legend-item {
    display: flex;
    margin-left: 2em;
    gap: 1em;
  }
  .legend-item span {
    font-size: 3.6vw;
  }
  .chart-area {
    margin-bottom: 2em;
    padding: 2em;
    background: rgb(255,255,255);
  }
  .score-area {
    padding: 2em 0 6vw;
    overflow-x: scroll;
    position: relative;
  }
  .score-area .score-date {
    display: block;
    width: 100%;
    font-size: 3.4vw;
    padding: 0 0 .5em;
  }
  .score-area div {
    display: flex;
    flex-flow: row nowrap;
    padding: 0 0 2.5em;
    background: rgb(255,255,255);
    font-size: 3vw;
  }
  .score-area::after {
    position: sticky;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    text-align: center;
    content: "横にスクロール";
    font-size: 3.6vw;
    margin-top: -1.6em;
  }
  .score-area div dl {
    font-size: 3.6vw;
    padding: 0;
    border: rgb(140, 158, 190) solid 1px;
    border-right: none;
  }
  .score-area div dl:last-child {
    border-right: rgb(140, 158, 190) solid 1px;
  }
  .score-area div dl dt {
    text-align: center;
    padding: .5em 2em;
    white-space: nowrap;
  }
  .score-area div dl dd {
    text-align: center;
    background: rgb(220, 230, 245);
    padding: .5em 2em;
  }
  .chart {
    padding: 4vw 0 6vw;
  }
  .chart .live {
    background: rgb(255,255,255);
  }
  .chart .btns label {
    font-size: 3.4vw;
    padding-right: 1em;
    cursor: pointer;
    transition: .3s;
  }
  .chart .btns label:hover {
    color: rgb(70, 150, 255);
  }
  .chart .average-value {
    display: flex;
    flex-flow: row-reverse wrap;
    justify-content: space-between;
    font-size: 3vw;
    padding: .5em 1em;
  }
  .note {
    padding: 0 0 8vw;
  }
  .note h3 {
    font-size: 3.8vw;
    border-top: rgb(200,200,200) solid 1px;
    border-bottom: rgb(200,200,200) solid 1px;
    margin: 0 0 .5em;
    padding: .5em;
  }
  .note ul {
    font-size: 3.4vw;
  }
  .note ul li {
    margin-bottom: .5em;
    padding-bottom: .5em;
    border-bottom: rgb(200,200,200) dashed 1px;
  }
  .note ul li p em {
    font-style: normal;
    padding-left: 1em;
  }
  .memo-ipt {
    font-size: 3.4vw;
  }
  .memo-ipt p {
    padding-bottom: .5em;
  }
  .note .memo-ipt input {
    font-size: 3.4vw;
    display: block;
    width: 100%;
    margin-bottom: .5em;
    box-sizing: border-box;
  }
  .memo-ipt p button {
    display: inline-block;
    margin-left: 1em;
  }
  p.note-r {
    font-size: 3vw;
    text-align: right;
    padding: .5em 0 0;
  }
  .daybtn {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding: 2em 0 0;
  }
  .daybtn h3 {
    font-weight: normal;
    padding: 0 0 1em;
    width: 100%;
  }
  .daybtn button {
    width: 8em;
    margin: 0 2vw 6vw;
    padding: .8em 1em;
  }
}
@media all and (min-width: 480px) {
  .active-color-sample {
    width: 1.1vw;
    height: 1.1vw;
  }
  .sleep-color-sample {
    width: 1.1vw;
    height: 1.1vw;
  }
  .non-active-color-sample {
    width: 1.1vw;
    height: 1.1vw;
  }
  .non-rem-color-sample {
    width: 1.1vw;
    height: 1.1vw;
  }
  .rem-color-sample {
    width: 1.1vw;
    height: 1.1vw;
  }
  .awake-color-sample {
    width: 1.1vw;
    height: 1.1vw;
  }
  .btns {
    display: flex;
    flex-flow: row wrap;
  }
  .cont h2 {
    font-size: 1.4vw;
    padding-bottom: 1em;
  }
  section h3 {
    font-size: 1.2vw;
  }
  .info-notes {
    padding-bottom: 4vw;
  }
  .info-notes p {
    font-size: 1vw;
  }
  .info-notes p span {
    padding-right: 2em;
  }
  .info-notes p span:last-child {
    padding-right: 0;
  }
  .btns button {
    margin-right: 1.5vw;
  }
  .btns button:last-child {
    margin-right: 0px;
  }
  .btns.w100 {
    padding: 0 0 2vw;
  }
  .btns.w100 p {
    font-size: 1.2vw;
    padding: .4em 0;
  }
  .btns.w100 p span {
    padding-right: 1em;
  }
  .tl-nav {
    display: flex;
    flex-flow: row wrap;
    font-size: 1vw;
    padding-bottom: 1em;
  }
  .tl-nav p {
    padding-right: 2em;
  }
  .tl-nav .btns label {
    display: inline-block;
    padding-right: 1em;
    cursor: pointer;
  }
  .head-tit {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  .head-tit span {
    display: inline-block;
    font-size: 1vw;
    font-weight: normal;
    padding-left: 1.5em;
  }
  .chart-area {
    margin-bottom: 4em;
    padding: 4em;
    background: rgb(255,255,255);
  }
  .now .chart-container canvas {
    width: 100% !important;
    height: 350px !important;
  }
  .score-area {
    padding: 3em 0 2vw;
  }
  .score-area .score-date {
    width: 100%;
    font-size: 1vw;
    padding: 0 0 .5em;
  }
  .score-area div {
    display: flex;
    flex-flow: row wrap;
    padding: 0 1em 0;
    background: rgb(255,255,255);
  }
  .score-area div dl {
    font-size: 1vw;
    padding: 0;
    border: rgb(140, 158, 190) solid 1px;
    border-right: none;
  }
  .score-area div dl:last-child {
    border-right: rgb(140, 158, 190) solid 1px;
  }
  .score-area div dl dt {
    text-align: center;
    padding: .5em 3em;
  }
  .score-area div dl dd {
    text-align: center;
    background: rgb(220, 230, 245);
    padding: .5em 3em;
  }
  .legend {
    margin-top: 1em;
    display: flex;
  }
  .legend-item {
    display: flex;
    align-items: center;
    margin-left: 4em;
    gap: .5em;
  }
  .legend-item span {
    font-size: 1vw;
  }
  .note {
    padding: 0 0 4em;
  }
  .note h3 {
    font-size: 1.1vw;
    border-top: rgb(200,200,200) solid 1px;
    border-bottom: rgb(200,200,200) solid 1px;
    margin: 0 0 .5em;
    padding: .5em;
  }
  .note ul {
    font-size: 1vw;
  }
  .note ul li {
    margin-bottom: .5em;
    padding-bottom: .5em;
    border-bottom: rgb(200,200,200) dashed 1px;
  }
  .note ul li p em {
    font-style: normal;
    padding-left: 1em;
  }
  .memo-ipt,
  .memo-ipt select {
    font-size: 1vw;
    padding: .3em 1em;
  }
  .memo-ipt p {
    padding-bottom: .5em;
  }
  .note .memo-ipt input {
    font-size: 1vw;
    display: block;
    width: 100%;
    margin-bottom: .5em;
  }
  .memo-ipt p button {
    display: inline-block;
    margin-left: 1em;
  }
  p.note-r {
    font-size: 1vw;
    text-align: right;
  }
  .daybtn {
    display: flex;
    flex-flow: row wrap;
    padding: 2em 0 0;
  }
  .daybtn h3 {
    font-weight: normal;
    padding: 0 0 .5em;
    width: 100%;
  }
  .daybtn button {
    margin-right: 1em;
  }
}
</style>
